<template>
    <div class="page-prevSale-check-list">
        <Nav />
        <div class="header">
            <div class="title">出厂校验</div>
            <div class="header-right">
                <span class="btn" @click="switchAccount">切换登录</span>
                <span class="btn add-record" @click="handleClick">出厂校验</span>
            </div>
        </div>
        <div class="content">
            <div class="filter-box">
                <el-row :gutter="24">
                    <el-form label-width="90px" center>
                        <!-- <el-col :xs="24" :sm="12" :md="7" :lg="7" :xl="7" class="pl-10">
                            <el-form-item label="故障原因:">
                                <el-select v-model="warningType" @change="loadCheckList" placeholder="请选择">
                                <el-option v-for="item in tagList" :key="item.id" :label="item.tagName" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                        <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
                            <el-form-item label="质检时间:">
                                <el-col :span="11">
                                    <el-form-item prop="startTime">
                                        <el-date-picker type="date" @change="search(1)" placeholder="开始时间"
                                            v-model="startTime" style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col class="line" :span="2">-</el-col>
                                <el-col :span="11">
                                    <el-form-item prop="endTime">
                                        <el-date-picker type="date" @change="search(1)" placeholder="结束时间" v-model="endTime"
                                            style="width: 100%;"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="7" :lg="7" :xl="7" class="pl-10">
                            <el-form-item v-if="fType == 'yingwo'" label="探头ID:" prop="number">
                                <el-input placeholder="请输入探头ID" maxlength="8" @input="inputChange('search')"
                                    v-model="number">
                                    <template slot="append"><span @click="search">搜索</span></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item v-if="fType == 'lexing'" label="设备编码:" prop="number">
                                <el-input placeholder="请输入设备编码" maxlength="8" @input="inputChange('search')"
                                    v-model="number">
                                    <template slot="append"><span @click="search">搜索</span></template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
            </div>
            <div class="table-body">
                <el-table v-if="fType == 'yingwo'" :data="list" style="width: 100%">
                    <el-table-column label="探头 ID" prop="probeId">
                    </el-table-column>
                    <el-table-column label="质检结果" prop="passText">
                    </el-table-column>
                    <el-table-column label="质检时间" prop="time">
                    </el-table-column>
                </el-table>

                <el-table v-if="fType == 'lexing'" :data="list" style="width: 100%">
                    <el-table-column label="设备编码" prop="uniqueId">
                    </el-table-column>
                    <el-table-column label="信号强度" prop="deviceSignal">
                    </el-table-column>
                    <el-table-column label="操作人" prop="operationUserName">
                    </el-table-column>
                    <el-table-column label="质检时间" prop="time">
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="onChangePageSize" @current-change="onChangePageNo"
                    :page-no.sync="pageOptions.pageNo" :page-sizes="pageOptions.pageSizes" :page-size="pageOptions.pageSize"
                    center layout="total, sizes, prev, pager, next, jumper" :total="pageOptions.total">
                </el-pagination>
            </div>
        </div>
        <div class="footer">
            <img :src="ossHost + 'img/logo.png'" alt="">
        </div>
        <el-dialog :title="stepNum == 1 ? '上传表格' : '校验结束'" :visible.sync="isShowModal" :close-on-click-modal="false"
            :close-on-press-escape="false" @close="onCancel" center>
            <el-form :model="form">
                <el-form-item v-show="stepNum == 1" required :label-width="formLabelWidth">
                    <div>请点击添加excel文件,只支持.xls/.xlsx后缀文件<a target="_blank" :href="ossHost + 'file/1.xlsx'">excel模板下载</a>
                    </div>
                    <el-col :span="24">
                        <el-form-item prop="probeId">
                            <el-upload class="avatar-uploader" accept=".xls,.xlsx" action="" :show-file-list="false"
                                :before-upload="beforeAvatarUpload">
                                <span v-if="fileName" class="file-name">{{ fileName }}</span>
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item v-show="stepNum == 2" required :label-width="formLabelWidth">
                    <div v-if="failList.list.length">
                        <p><i class="el-icon el-icon-remove"></i>本次校验共{{ failList.total }}台设备，{{ failList.list.length }}台未通过质检
                        </p>
                        <el-table v-if="fType == 'yingwo'" :data="failList.list" style="width: 100%">
                            <el-table-column label="探头 ID" prop="probeId">
                            </el-table-column>
                            <el-table-column label="原因" prop="text">
                            </el-table-column>
                        </el-table>
                        <el-table v-if="fType == 'lexing'" :data="failList.list" style="width: 100%">
                            <el-table-column label="探头 ID" prop="uniqueId">
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-else>
                        <i class="el-icon el-icon-success"></i>
                        <p>本次校验共{{ failList.total }}台设备，全部通过了出厂质检</p>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="onCancel" :loading="loading">{{ stepNum == 1 ? '取 消' : '关 闭' }}</el-button>
                <el-button type="primary" v-if="stepNum == 1" @click="onConfirm" :disabled="!isConfirmPass"
                    :loading="loading">开始校验</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { getYMD } from '@/utils/time'
import Nav from '@/components/nav'
export default {
    name: "RyPreSaleCheckTestList",
    components: {
        Nav
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            startTime: '',
            endTime: '',
            number: '',
            list: [],
            isShowModal: false,
            formLabelWidth: '1px',
            pageOptions: {
                pageSizes: [10, 20, 50, 100],
                pageSize: 10,
                total: 0,
                pageNo: 1
            },
            form: {
                probeId: '',
                tagId: '',
                remark: ''
            },
            isConfirmPass: false,
            singleItem: {},
            loading: false,
            fType: '',
            stepNum: 1,
            fileName: '',
            failList: { total: 0, list: [] }
        };
    },
    created() {
        const vm = this;
        const { USER_NAME } = vm.$config.keys;
        const name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;

        const fType = vm.$strTool.filterFactoryType(uuid);
        vm.$localStorage.setItem('uuid', uuid)
        vm.fType = fType;
        vm.name = name;
        if (vm.$config.base.fList.indexOf(fType) > -1) {
            (['yingwo', 'lexing', 'lexing'].indexOf(fType) == -1 || !name) && vm.$router.replace({
                path: "/ryProbe/afterSale/login"
            });
        } else {
            vm.$router.replace({
                path: "404"
            });
        }

        vm.pageNo = 1;
        vm.pageSize = 10;
        vm.loadCheckList();
    },
    methods: {
        switchAccount() {
            const vm = this;
            Dialog.confirm({
                title: "提示",
                message: "是否确认退出当前账号？",
                confirmButtonColor: "#32BE32"
            })
                .then(() => {
                    const { USER_NAME } = vm.$config.keys;
                    vm.$localStorage.removeItem(USER_NAME);
                    vm.$router.replace({
                        path: "/ryProbe/afterSale/login"
                    });
                })
                .catch(() => {
                    // on cancel
                });
        },

        search(type) {
            const vm = this;
            let err = ''
            if (vm.number.length < 5 && type != 1) {
                err = '请输入正确的ID'
            }

            if (type == 1 && (vm.startTime && vm.endTime)) {
                // !(vm.startTime && vm.endTime) && (err = '请选择开始跟结束时间')
                new Date(vm.endTime).getTime() < new Date(vm.startTime).getTime() && (err = '结束时间不能早于开始时间')
            }

            if (err) {
                Toast.fail({
                    message: err
                })
                return
            }
            vm.loadCheckList();
        },
        onChangePageNo(pageNo) {
            this.pageOptions.pageNo = pageNo
            this.loadCheckList()
        },
        onChangePageSize(pageSize) {
            this.pageOptions.pageSize = pageSize
            this.loadCheckList()
        },
        loadCheckList() {
            const vm = this;
            if (['lexing', 'yingwo'].indexOf(vm.fType) == -1) {
                return
            }
            const params = {
                pageNo: vm.pageOptions.pageNo,
                pageSize: vm.pageOptions.pageSize
            }
            if (vm.fType == 'yingwo') {
                vm.number && (params.probeId = vm.number)
            } else {
                vm.number && (params.uniqueId = vm.number)
            }

            vm.startTime && (params.startAt = new Date(vm.startTime).getTime() / 1000)
            vm.endTime && (params.endAt = new Date(vm.endTime).getTime() / 1000)

            vm.loading = true;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.ADMIN_URL}admin/iotTool/product/preSales/${vm.fType == 'yingwo' ? 'probe' : 'ryy'}`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.list.map(item => {
                    item.time = getYMD(item.createAt)
                    item.passText = Number(item.passed) ? '通过' : '未通过'
                })
                vm.list = res.data.list;
                vm.pageOptions.total = res.data.total
                vm.pageOptions.pageNo = vm.pageNo;
            });
        },
        handleClick() {
            this.isShowModal = true;
            this.stepNum = 1;
            this.isConfirmPass = false;
            this.fileName = '';
            this.file = '';
        },

        onCancel() {
            this.editItem = {};
            this.singleItem = {};
            this.singleItem = {};
            this.isShowModal = false;
            this.loading = false;
        },
        onConfirm() {
            const vm = this
            vm.loading = true
            const file = new FormData()
            file.append('file', vm.file)
            vm.$http({
                type: "POST",
                url: `${vm.$config.base.ADMIN_URL}admin/iotTool/product/preSales/${vm.fType == 'yingwo' ? 'probe' : 'ry'}/check`,
                // url: 'http://127.0.0.1:3011/admin/iotTool/product/preSales/ry/check',
                // except: true,
                data: file
            }).then(res => {
                vm.loading = false;
                const { code, data, message } = res
                if (code != 1) {
                    Toast.fail({
                        message
                    })
                    return
                }
                vm.failList = data;
                vm.stepNum = 2;
            }).catch(err => {
                vm.loading = false;
                console.log(err)
            });
        },
        inputChange(type) {
            const vm = this
            if (type == 'search') {
                !vm.number && vm.loadCheckList();
                return
            }
        },

        beforeAvatarUpload(file) {
            const vm = this
            vm.fileName = file.name;
            vm.isConfirmPass = true;
            vm.file = file;
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";

.page-prevSale-check-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    padding: 100px 0 70px;
    font-size: 16px;
    color: @textColor9;
    padding-left: 160px;

    * {
        box-sizing: border-box;
    }

    .header {
        position: absolute;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        top: 0;
        line-height: 60px;
        padding: 20px 20px;
        padding-left: 180px;
        z-index: 5;
        background: @blank;

        .title {
            color: @textColor10;
            font-size: 24px;
            font-weight: bold;
        }

        .header-right {
            font-size: 14px;

            .btn {
                height: 30px;
                line-height: 30px;
                width: 90px;
                text-align: center;
                border: 1px solid @textColor9;
                border-radius: 4px;
                display: inline-block;
                cursor: pointer;

                &.add-record {
                    background: @textColor3;
                    color: @blank;
                    border-color: @textColor3;
                    margin-left: 20px;
                }
            }
        }
    }

    .footer {
        height: 60px;
        padding: 10px 20px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 2px -3px 4px #bbb;
        padding-left: 180px;
        text-align: right;

        img {
            height: 35px;
        }
    }

    .content {
        padding: 60px 20px 80px;
        height: 100%;
        overflow-y: auto;

        .filter-box {
            padding: 10px 20px;
            position: fixed;
            left: 0px;
            right: 0px;
            z-index: 5;
            top: 90px;
            background: @blank;
            box-shadow: -2px 3px 4px #bbb;
            height: 60px;
            padding-left: 180px;
        }

        .el-input-group__append {
            padding: 0 20px;
            cursor: pointer;
            background: @textColor3;
            color: @blank;
            border: 1px solid @textColor3;
        }

        .pl-10 {
            .el-input__inner[type="text"] {
                padding-left: 10px;
            }
        }

        .el-pagination {
            padding-top: 30px;
            text-align: center;
        }
    }

    .el-dialog {
        margin: 0 auto;

        .warning-tip {
            text-decoration: underline;
            color: @textColor2;
            cursor: pointer;
        }

        .el-form {
            text-align: center;
        }

        .avatar-uploader {
            display: flex;
            align-items: center;

            .el-upload {
                width: 200px;
                height: 200px;
                line-height: 200px;
                border: 1px dashed @textColor2;
                margin: 0 auto;

                i {
                    font-size: 100px;
                    line-height: 200px;
                }
            }
        }

        .el-icon {
            font-size: 20px;
            color: #67C23A;
            vertical-align: middle;

            &.el-icon-success {
                font-size: 80px;
            }

            &.el-icon-remove {
                color: #F56C6C;
                margin-right: 10px;
            }
        }
    }
}

.van-dialog {
    z-index: 99999;
}
</style>


